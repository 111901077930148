body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: #fff;
  height: 100%;
  overflow-x: hidden; /* Allow vertical scrolling */
}

html, body, #root {
  height: 100%;
  overflow-x: hidden; /* Allow vertical scrolling */
}

.landing-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Allow vertical scrolling */
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

.container {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto; /* Allow vertical scrolling */
  padding-bottom: 50px; /* To ensure space at the bottom for scrolling */
}

.header-logo {
  position: absolute;
  top: 40px;
  right: 40px;
  display: flex;
}

.header-image {
  display: block;
  width: 100px;
  margin: 0 auto;
}

.textContainer, .textContainer2, .textContainer3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 390px;
  margin: 0 auto;
}

#customButton, #customButton2 {
  align-items: center;
  background-color: #000;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  margin-top: 25px;
  max-width: 300px;
  padding: 16px 32px;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

#customButton2 .small-text {
  font-size: 12px;
  font-weight: normal;
}

.onlyfans, .cashapp, .tiktok, .x {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 5px;
}

.onlyfans {
  width: 25px;
  height: 24px;
}

.cashapp {
  width: 35px;
  height: 30px;
}

.tiktok {
  width: 25px;
  height: 25px;
}

.x {
  width: 20px;
  height: 20px;
}

/* Ensure content is scrollable on smaller screens */
@media only screen and (max-width: 600px) {
  .header-logo {
    top: 15px;
    right: 15px;
  }

  .text-style {
    font-size: 16px;
  }

  .footer {
    margin-top: 100px;
  }
}

/* Existing styles */
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.swiper-container {
  position: relative;
  overflow: hidden;
  width: 75vw; /* Adjusted to viewport width */
  height: 100%; /* Adjusted to viewport height */
  margin: auto;
  max-width: 591px; /* Maximum width */
  max-height: 350px; /* Maximum height */
}

.swiper-slide img {
  width: 100%; /* Full width of the slide */
  height: auto; /* Maintain aspect ratio */
  background-size: cover;
  background-position: center;
  z-index: 2;
}

@media only screen and (max-width: 600px) {
  .swiper-container {
    width: 90vw; /* Adjusted to a smaller viewport width */
    height: 100%; /* Adjusted to a smaller viewport height */
  }
}

.header-image {
  display: block;
  height: auto;
  width: 100px; /* Adjusted width */
  margin: 0 auto; /* Center the image */
}

.header-logo {
  display: flex;
  position: absolute;
  top: 40px;
  right: 40px;
}

/* Styles from HTML page */
body {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  height: 100%;
  color: #fff;
  font-family: "Roboto", sans-serif;
  width: 100%;
  overflow-x: hidden; /* Allow vertical scrolling */
}

/* In App.css */
.landing-container {
  position: relative;
  width: 100vw;
  height: 100vh; /* Adjust as per your layout */
  overflow-y: auto; /* Allow vertical scrolling */
}

.container {
  margin-top: 15px;
  position: relative;
  z-index: 2; /* Bring the main content above the video */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
}

.textContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.textContainer2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0; /* Added 10px padding on top and bottom */
}

.textContainer3 {
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Ensure items are stacked vertically */
  align-items: center; /* Center items horizontally */
  width: 100%; /* Make sure the container takes the full width */
  max-width: 390px; /* Max width to keep it consistent */
  margin: 0 auto; /* Center align the container */
}

#time-in-current-location {
  text-align: center;
  margin-top: 40px;
  padding-top: 40px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
}

.textContainer > section {
  display: flex;
  flex-direction: column;
}

.textContainer > img {
  height: 50px;
  width: 50px;
  object-fit: fill;
  margin-right: 5px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonStyling {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 4px 4px 4px 4px;
  color: #0fcc0f;
  background-color: rgb(147 252 147 / 56%);
  font-weight: 600;
  margin-left: 5px;
  font-size: 12px;
}

.text-style {
  font-size: 24px;
  margin: 0; /* Remove default margins */
}

#user-city {
  text-align: center; /* Center the user's city text */
}

@media only screen and (max-width: 600px) {
  .text-style {
    font-size: 16px;
  }
}

#customButton {
  align-items: center;
  background-color: #000;
  border-radius: 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 56px;
  justify-content: center;
  margin-top: 20px;
  max-width: 300px; /* Set max-width to 300px */
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.countdown-block {
  margin-top: 16px;
}

@media only screen and (max-width: 600px) {
  .swiper-container {
    width: 90vw; /* Adjusted to a smaller viewport width */
    height: 100%; /* Adjusted to a smaller viewport height */
  }

  .header-logo {
    top: 15px;
    right: 15px;
  }

  .text-style {
    font-size: 16px;
  }

  .footer {
    margin-top: 100px; /* Ensures footer has enough space on mobile */
  }
}

#customButton2 {
  align-items: center;
  background-color: #000;
  border-radius: 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  margin-top: 25px;
  max-width: 300px; /* Set max-width to 300px */
  padding: 16px 32px;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

#customButton2 img {
  width: 1em;
  height: 1em;
  margin-left: 5px;
  margin-top: 5px;
}

#customButton2 .cashapp {
  width: 35px;
  height: 30px;
}

#customButton2 .textContent {
  display: flex;
  flex-direction: column;
  text-align: center;
}

#customButton2 .small-text {
  font-size: 12px; /* Smaller font size */
  font-weight: normal; /* Not bold */
}

.linktree {
  background-image: url(../images/footer.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 14px;
  height: 16px;
}

.onlyfans {
  background-image: url(../images/onlyfans.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  height: 24px;
  margin-right: 5px;
}

.snapchat {
  background-image: url(../images/snapchat.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 32px;
  margin-right: 5px;
}

.fansly {
  background-image: url(../images/fansly.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  height: 20px;
  margin-right: 5px;
}

.cashapp {
  background-image: url(../images/cashapp.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 35px;
  height: 30px;
  margin-right: 5px;
}

.tiktok {
  background-image: url(../images/tiktok.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.x {
  background-image: url(../images/x.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.youtube {
  background-image: url(../images/youtube.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 40px;
  height: 30px;
  margin-right: 5px;
}

.facebook {
  background-image: url(../images/facebook.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.location {
  background-image: url(../images/location.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

#customButton img {
  width: 1em;
  height: 1em;
  margin-left: 5px;
  margin-top: 5px;
}

.exclusiveContent {
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin-top: 20px; /* Add margin for spacing */
}

.username {
  font-size: 32px;
  color: white;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  margin-top: 8px; /* Add margin for spacing */
}

.useraccountname {
  font-size: 20px;
  color: white;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  margin-top: 2px; /* Add margin for spacing */
}
.separator {
  background-image: url(./images/separator.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 2px;
  height: 19px;
}

.status {
  background-image: url(./images/status.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 10px;
  height: 10px;
}

.footer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: auto; /* Ensures the footer sticks to the bottom */
  padding-bottom: 75px;
}

.page-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 8px;
}

.user-status::before {
  display: none;
  content: "";
  position: absolute;
  background-image: url(./images/status.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 10px;
  height: 10px;
  left: -15px;
}

.clock {
  background-image: url(./images/clock.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 22px;
  height: 22px;
}

.top-right-button {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 10;
}

.top-right-button img {
  width: 50px; /* Adjust based on your needs */
  height: auto;
}

.user-status {
  margin-top: 40px;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}

.user-status2 {
  margin-top: 10px;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}

.discount {
  color: #ff0000;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
